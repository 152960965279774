<template>
  <div class="space-y-4">
    <article>
      <h3 class="subheader">Quick Links</h3>
      <br />
      <section
        class="relative z-0 rounded-md grid sm:grid-cols-1 md:grid-cols-4"
      >
        <asom-button
          v-if="canCreateStationFault"
          variant="primary"
          outline
          rounded="none"
          @click="
            $router.push({
              name: 'Create Station Fault',
            })
          "
          icon="file-document"
          text="Create Station Fault"
        >
        </asom-button>
        <asom-button
          variant="primary"
          outline
          rounded="none"
          @click="
            $router.push({
              name: 'E-booklets Main Page',
            })
          "
          icon="file-document"
          text="E-booklets"
        >
        </asom-button>
      </section>
    </article>
    <asom-alert v-if="error" variant="error" :error-message="error" />
    <asom-card title="Station Fault">
      <div class="grid sm:grid-cols-1 md:grid-cols-2 gap-x-6 gap-4">
        <asom-form-field label="Line" required>
          <asom-input-select
            :disabled="!canSelectLine"
            v-model="filters.line"
            :options="lineOptions"
          />
        </asom-form-field>
        <asom-form-field label="Station" required>
          <asom-input-select
            v-model="filters.station"
            :objectModelValue="false"
            :options="stationOptions"
          />
        </asom-form-field>
        <asom-form-field label="Date" required>
          <asom-input-date-range
            v-model="filters.dateRange"
            datepicker-placement="bottom"
          />
        </asom-form-field>
        <asom-form-field label="Work Order Status">
          <asom-multi-select
            :objectModelValue="false"
            v-model="filters.workOrderStatus"
            :options="workOrderStatusOptions"
          />
        </asom-form-field>
      </div>
      <template #footer>
        <asom-export-to-excel
          fileName="Station Faults"
          :fields="columns.fields"
          :labels="columns.labels"
          :data="columns.data"
        />
        <asom-button
          text="Reset Filters"
          variant="secondary"
          @click="resetFilters"
        />
        <asom-button text="Apply" @click="getPageData(true)" />
      </template>
    </asom-card>
    <asom-card title="Station Faults">
      <asom-client-table
        v-if="!isLoading"
        :columns="[
          'faultNo',
          'description',
          'dateCreated',
          'timeCreated',
          'createdBy',
          'notificationId',
          'workOrderId',
          'workOrderStatus',
          'isMaintainanceStaffInformed',
          'workCenter',
          'remarks',
        ]"
        :sortableColumns="[
          'faultNo',
          'description',
          'dateCreated',
          'createdBy',
          'notificationId',
          'workOrderId',
          'workOrderStatus',
          'isMaintainanceStaffInformed',
          'workCenter',
          'remarks',
        ]"
        :data="tableData"
        :searchableDateColumns="['dateCreated']"
        :searchableTimeColumns="['dateCreated']"
      >
        <template v-slot:header_workCenter>Work Centre</template>
        <template v-slot:header_notificationId
          >Notification ID/ Trag No / OCC No.</template
        >
        <template v-slot:notificationId="scopedSlots">
          <span>{{ scopedSlots.data ? scopedSlots.data : "" }}</span>
        </template>
        <template v-slot:workOrderId="scopedSlots">
          <span>{{ scopedSlots.data ? scopedSlots.data : "" }}</span>
        </template>
        <template v-slot:workOrderStatus="scopedSlots">
          <span>{{ scopedSlots.data ? scopedSlots.data : "" }}</span>
        </template>
        <template v-slot:dateCreated="scopedSlots">
          <span>{{
            scopedSlots.data ? displayUtcDate(scopedSlots.data) : "NA"
          }}</span>
        </template>
        <template v-slot:timeCreated="scopedSlots">
          <span>{{
            displayUtcTime(get(scopedSlots.rowData, "dateCreated"))
          }}</span>
        </template>
        <template v-slot:faultNo="scopedSlots">
          <button
            v-if="canViewStationFault"
            @click="navigateToView(scopedSlots.rowData)"
            class="text-button underline font-semibold"
          >
            {{ scopedSlots.data }}
          </button>
          <div v-else>
            {{ scopedSlots.data }}
          </div>
        </template>
        <template v-slot:isMaintainanceStaffInformed="scopedSlots">
          <span>{{ scopedSlots.data ? "YES" : "NO" }}</span>
        </template>
        <template v-slot:remarks="scopedSlots">
          <span v-if="scopedSlots.data"
            ><remarks-tooltip :text="scopedSlots.data" /></span
          ><span v-else>{{ "NA" }}</span>
        </template>
      </asom-client-table>
      <div v-else class="text-center">
        <asom-icon icon="spinner" class="animate-spin" />
      </div>
    </asom-card>
    <asom-card title="Activity Log">
      <asom-client-table
        v-if="!isLoading"
        :columns="[
          'stationFaultTransactionNo',
          'stationFaultNo',
          'transactionType',
          'createdBy',
          'remarks',
        ]"
        :sortableColumns="[
          'stationFaultTransactionNo',
          'stationFaultNo',
          'transactionType',
          'createdBy',
          'remarks',
        ]"
        :data="activityLog"
      >
        <template v-slot:stationFaultTransactionNo="scopedSlots">
          <button
            @click="navigateToTransactionView(scopedSlots.rowData)"
            class="text-button underline font-semibold"
          >
            {{ scopedSlots.data }}
          </button>
        </template>
        <template v-slot:remarks="scopedSlots">
          <span v-if="scopedSlots.data"
            ><remarks-tooltip :text="scopedSlots.data" /></span
          ><span v-else>{{ "NA" }}</span>
        </template>
      </asom-client-table>
      <div v-else class="text-center">
        <asom-icon icon="spinner" class="animate-spin" />
      </div>
    </asom-card>
  </div>
</template>

<script>
import get from "lodash.get";
import moment from "moment";
import { mapGetters } from "vuex";
import {
  toMoment,
  displayUtcDate,
  displayUtcTime,
} from "@/helpers/dateTimeHelpers";
import { getListOfStationFault } from "../../../services/faultManagement.service";
import RemarksTooltip from "../../_RemarksTooltip.vue";
import { setStorage, getStorage } from "@/helpers/sessionStorage";

export default {
  components: {
    RemarksTooltip,
  },
  data() {
    return {
      filters: {
        line: this.$store.getters["auth/userLineOption"],
        station: this.$store.getters["selectedStation/id"],
        dateRange: {
          start: moment().subtract(1, "month").toDate(),
          end: moment().add(1, "months").toDate(),
        },
        workOrderStatus: ["includeOpenedWorkOrder", "includeClosedWorkOrder"],
      },
      isLoading: false,
      isSubmitting: false,
      faultOccurrenceList: [],
      activityLog: [],
      error: null,
      selectedItem: null,
    };
  },
  mounted() {
    const searchVal = getStorage("fault-mgnt");
    if(searchVal) {
      // UTC时间转换成对应的本地时间
      if(searchVal.dateRange && searchVal.dateRange.start)  searchVal.dateRange.start = moment.utc(searchVal.dateRange.start).toDate();
      if(searchVal.dateRange && searchVal.dateRange.end) searchVal.dateRange.end = moment.utc(searchVal.dateRange.end).toDate();
      this.filters = searchVal;
    }
    this.$nextTick(() => {
      this.getPageData();
    })
  },
  computed: {
    ...mapGetters({
      lineOptions: "smrtResource/lineOptions",
      canSelectLine: "auth/canSelectLine",
      canCreateStationFault: "auth/canCreateStationFault",
      canViewStationFault: "auth/canViewStationFault",
    }),
    workOrderStatusOptions() {
      return [
        {
          label: "Opened Work Orders",
          value: "includeOpenedWorkOrder",
        },
        {
          label: "Closed Work Orders",
          value: "includeClosedWorkOrder",
        },
      ];
    },
    stationOptions() {
      return this.$store.getters["smrtResource/stationOptionsByLineId"](
        get(this.filters.line, "value")
      );
    },
    tableData() {
      return this.faultOccurrenceList;
    },
    queryParams() {
      let params = {};
      if (
        this.filters.dateRange &&
        this.filters.dateRange.start &&
        this.filters.dateRange.end
      ) {
        params["searchDateFrom"] = this.parseDateTimeUTC(this.filters.dateRange.start, true);
        params["searchDateTo"] = this.parseDateTimeUTC(this.filters.dateRange.end, false);
      }
      params["lineId"] = get(this.filters.line, "value");
      params["stationId"] = this.filters.station
        ? this.filters.station
        : null;
      if (this.filters.workOrderStatus.length > 0) {
        if (this.filters.workOrderStatus.includes("includeOpenedWorkOrder"))
          params["includeOpenedWorkOrder"] = true;
        else params["includeOpenedWorkOrder"] = false;
        if (this.filters.workOrderStatus.includes("includeClosedWorkOrder"))
          params["includeClosedWorkOrder"] = true;
        else params["includeClosedWorkOrder"] = false;
      }
      return params;
    },
    columns() {
      const fields = [
          'faultNo',
          'description',
          'dateCreated',
          'timeCreated',
          'createdBy',
          'notificationId',
          'workOrderId',
          'workOrderStatus',
          'isMaintainanceStaffInformed',
          'workCenter',
          'momsNotification_FunctionalLocation',
          'remarks',
        ];
      const labels = [
          'Fault No',
          'Description',
          'Date Created',
          'Time Created',
          'Created By',
          'Notification ID/ Trag No / OCC No.',
          'WorkOrder Id',
          'WorkOrder Status',
          'Is Maintainance Staff Informed',
          'Work Center / Afc',
          'Functional Location',
          'Remarks',
        ];
      const data = [];
      this.faultOccurrenceList.forEach((row, i) => {
        data[i] = fields.reduce((accm, field) => {
          let d = row[field];
          if (field == "dateCreated") {
            d = displayUtcDate(d);
          }
          if (field == "timeCreated") {
            d = row["dateCreated"];
            d = d ? displayUtcTime(d) : '-';
          }
          if (field == "isMaintainanceStaffInformed") {
            d = d ? "YES" : "NO";
          }
          if (field == "remarks") {
            d = d ? d : "NA";
          }
          accm[field] = d;
          return accm;
        }, {});
      });
      return {
        fields,
        labels,
        data,
      };
    },
  },
  methods: {
    get,
    displayUtcDate,
    displayUtcTime,
    resetFilters() {
      this.filters.dateRange = null;
    },
    navigateToView(selectedItem) {
      if (!get(selectedItem, "faultId", null))
        selectedItem.faultId = selectedItem.stationFaultId;
      this.$router.push({
        name: "View Station Fault",
        params: selectedItem,
      });
    },
    navigateToTransactionView(selectedItem) {
      if (get(selectedItem, "transactionId", null) == null) {
        selectedItem.transactionId = get(
          selectedItem,
          "stationFaultTransactionId",
          null
        );
      }
      this.$router.push({
        name: "View Station Fault Transaction Details",
        params: selectedItem,
      });
    },
    async getPageData(status) {
      if(status) setStorage("fault-mgnt", this.filters);
      this.isLoading = true;
      const resp = await getListOfStationFault({
        ...this.queryParams,
      });
      if (resp.success) {
        this.faultOccurrenceList = get(resp.payload, "stationFaults", []);
        this.activityLog = get(resp.payload, "transactions", []);
        this.isLoading = false;
        this.error = null;
      } else {
        this.isLoading = false;
        this.error = resp.payload;
        this.$scrollTop();
        return;
      }
    },
    parseDateTimeUTC(datetimeValue, isStartDate) {
      var d = toMoment(datetimeValue);
      if (d.isValid()) {
        var _d = isStartDate ? d.startOf('day') : d.endOf('day');
        const UTC_d = moment.utc(_d);
        return UTC_d.format("YYYY-MM-DD") + "T" + UTC_d.format("HH:mm:ss")
      }
      return ""
    }
  },
};
</script>
